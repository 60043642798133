import axios from "axios";
import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes";
import format from "date-fns/format";
import { Link, navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Layout from "../components/layout";
import CartAddToCart from "../components/partials/cart-add-to-cart";
import CartItemSummary from "../components/partials/cart-item-summary";
import Seo from "../components/seo";
import withLocation from "../components/shared/withLocation";
import CartContext from "../context/cart-context";
import { formatPrice } from "../utilities/price";

function AddAccessoriesToYourCart({ search }) {
  const cartContext = useContext(CartContext);
  const recommendedProducts = cartContext.recommendedProducts()
    ? cartContext.recommendedProducts()
    : [];

  const [hasDiscount, setHasDiscount] = useState(false);
  const [discountMultiplier, setDiscountMultiplier] = useState(1);
  const [expiration, setExpiration] = useState(new Date());

  useEffect(() => {
    function setDiscountOnCart(discount) {
      cartContext.setDiscount(discount);
      cartContext.refreshCartTotals();
    }

    if (search.order) {
      cartContext.setReferenceOrder(search.order);
    }

    if (!hasDiscount) {
      axios
        .get(
          `${process.env.DATA_API}/wp-json/${process.env.COMPANY_NAME_SLUG}/v1/discounts/?code=${search.discount}`
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.length) {
              if (new Date() > new Date(response.data[0].expiration)) {
                toast.error("That offer has expired");
              } else {
                setHasDiscount(true);
                setDiscountOnCart({
                  name: response.data[0].name,
                  amount: response.data[0].amount_off,
                  percent: response.data[0].percent_off,
                  categories: response.data[0].categories,
                  expiration: new Date(response.data[0].expiration),
                });
                if (response.data[0].percent_off) {
                  setDiscountMultiplier(1 - response.data[0].percent_off / 100);
                }
                if (response.data[0].expiration) {
                  setExpiration(new Date(response.data[0].expiration));
                }
                cartContext.setOrderRequiresNoDeposit();
              }
            }
          } else {
            console.log(response); // error
          }
        });
    }
  }, [search, hasDiscount, cartContext]);

  useEffect(() => {
    if (recommendedProducts.length > 1) {
      cartContext.setCartRedirect("/add-accessories-to-your-order/");
    } else {
      cartContext.setCartRedirect("/checkout/");
    }
  }, [recommendedProducts.length, cartContext]);

  return (
    <Layout>
      <Seo title="Window Well Cover Accessories" />
      <div className="container px-4 mx-auto my-8 md:my-16">
        <div className="flex flex-wrap justify-end md:justify-between">
          <div className="max-w-lg">
            <h1>Your Window Well Covers Are Currently In Production</h1>
            <p className="pt-2">
              You've got your window well covers ordered, now take a discount on
              our accessories. Add these accessories without paying anything
              until they're installed!
            </p>
          </div>
          {hasDiscount ? (
            <div className="mt-4">
              <p className="text-red-dark font-medium text-lg">
                This offer expires at {format(expiration, "h:mm bbbb")}{" "}
                {expiration.getDate() === new Date().getDate() + 1
                  ? "tomorrow"
                  : null}{" "}
                ({differenceInHours(expiration, new Date())} hours and{" "}
                {differenceInMinutes(expiration, new Date()) % 60} minutes)
              </p>
            </div>
          ) : null}
        </div>
        <div className={`flex flex-wrap mt-8`}>
          <div className="pb-4 w-full text-grey-darkest">
            <div className="flex flex-wrap -mx-4">
              {recommendedProducts.map((product) => {
                const price = +product.node.product.salePrice
                  ? +product.node.product.salePrice
                  : +product.node.product.basePrice;
                return (
                  <div
                    className="p-4 mx-auto md:mx-0 w-full md:w-1/3"
                    key={product.node.product.sku}
                  >
                    <div className="max-w-sm rounded overflow-hidden shadow-lg">
                      <div
                        className="h-64 w-full cursor-pointer"
                        style={{
                          backgroundImage: `url(${product.node.featuredImage.node.localFile.publicURL})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                        onClick={() => navigate(`/${product.node.slug}/`)}
                        onKeyDown={() => navigate(`/${product.node.slug}/`)}
                        role="button"
                        tabIndex="0"
                      ></div>
                      <div className="px-6 pt-6">
                        <div className="font-bold text-xl mb-4">
                          <Link to={`/${product.node.slug}/`}>
                            {product.node.title}
                          </Link>
                        </div>
                        <p className="text-grey-darker text-base">
                          {product.node.product.cartCalloutText}
                        </p>
                      </div>
                      <div className="px-6 md:py-4">
                        <p className="mt-3">
                          <span
                            className={`${
                              price === +product.node.product.basePrice &&
                              discountMultiplier === 1
                                ? "hidden "
                                : ""
                            }line-through text-lg pr-2`}
                          >
                            {formatPrice(
                              product.node.product.basePrice
                                ? product.node.product.basePrice
                                : price
                            )}
                          </span>
                          <span className="text-xl">
                            {formatPrice(
                              cartContext.getItemDiscount(product.node.product)
                            )}
                          </span>
                        </p>
                      </div>
                      <div className="px-6 pb-6 md:pt-4">
                        <div className="flex mt-2 md:mt-6 items-center justify-between w-full">
                          <CartAddToCart
                            product={product}
                            buttonClasses={`bg-${process.env.THEME_COLOR_PRIMARY} shadow appearance-none border rounded ml-2 py-2 px-3 text-${process.env.THEME_COLOR_PRIMARY}-lightest leading-tight focus:outline-none focus:ring h-10 uppercase`}
                            allowAddWithNoCovers={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="my-4 md:mx-4">
          <h2>Your Cart</h2>
          <div className="flex flex-wrap mt-2 md:mx-8">
            <div className="md:p-4 w-full">
              {cartContext.contents.map((value, index) => {
                return (
                  <CartItemSummary
                    key={index}
                    id={value.id}
                    url={value.url}
                    image={value.image}
                    price={value.salePrice ? value.salePrice : value.basePrice}
                    title={value.title}
                    quantity={value.quantity}
                    attributes={value.attributes ? value.attributes : []}
                  />
                );
              })}
              <div
                className={`overflow-y-scroll p-8 ${
                  cartContext.contents.length ? "hidden" : ""
                }`}
              >
                <h3>Your cart is empty</h3>
              </div>
            </div>
          </div>
        </div>
        <div className={`flex justify-end py-4 md:px-0 md:mr-4 uppercase`}>
          <Link
            className={`bg-${
              process.env.THEME_COLOR_PRIMARY
            } text-white text-sm px-4 py-3 no-underline rounded ${
              !cartContext.contents.length ? "hidden" : "block md:inline-block"
            }`}
            to="/checkout/"
          >
            Go To Checkout
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default withLocation(AddAccessoriesToYourCart);
